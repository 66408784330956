import { useDispatch, useSelector } from "react-redux";
import { setDate, setName } from "../../redux/actions/actions";

const InvoiceDateName = () => {
  const selector = useSelector((state) => state.invoice);
  const dispatch = useDispatch();

  const handleNameChange = (e) => {
    dispatch(setName(e.target.value));
  }

  const handleDateChange = (e) => {
    dispatch(setDate(e.target.value));
  }

  return (
    <>
      <div className='flex flex-row w-full justify-start mx-[20%] mt-3'>
        <div>
          <div>
            <span
              className='mr-2 
              font-bold 
              text-black 
              outline-none 
              active:outline-none 
              hove:outline-none'
            >Date: </span>
            <span className='font-bold p-0 border-0'>
              {selector.date}
            </span>
          </div>
          <div>
            <span
              className='mr-2 
              font-bold 
              text-black 
              outline-none 
              active:outline-none 
              hove:outline-none'
            >Invoice for </span>
            <span className='font-bold p-0 border-0'>
              {selector.name}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceDateName;