import {
  ADD_ROW,
  CHANGE_DATE,
  CHANGE_NAME,
  CHANGE_INVOICE_NUMBER,
  DELETE_ROW,
  UPDATE_ROW,
  ADD_ARTWORK_CHARGE,
  ADD_FREIGHT_CHARGE,
  CALCULATE_SUBTOTAL
} from "./types";

export const setDate = (date) => {
  return {
    type: CHANGE_DATE,
    payload: date
  }
}

export const setName = (name) => {
  return {
    type: CHANGE_NAME,
    payload: name,
  }
}

export const setInvoiceNumber = (invoiceNumber) => {
  return {
    type: CHANGE_INVOICE_NUMBER,
    payload: invoiceNumber,
  }
}

export const addRow = (row) => {
  return {
    type: ADD_ROW,
    payload: row,
  }
}

export const updateRow = (row) => {
  return {
    type: UPDATE_ROW,
    payload: row
  }
}

export const deleteRow = (rowId) => {
  return {
    type: DELETE_ROW,
    payload: rowId,
  }
}

export const addFreightCharge = (freightCharge) => {
  console.log("addFreightCharge");
  return {
    type: ADD_FREIGHT_CHARGE,
    payload: freightCharge,
  }
}

export const addArtworkCharge = (artworkCharge) => {
  console.log("addArtworkCharge");
  return {
    type: ADD_ARTWORK_CHARGE,
    payload: artworkCharge,
  }
}

export const calculateSubtotal = () => {
  return {
    type: CALCULATE_SUBTOTAL,
  }
}