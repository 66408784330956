import { XCircleIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import { deleteRow } from "../../redux/actions/actions";

const InvoiceItems = ({ index, length, row, reduceRowInserted }) => {
  const dispatch = useDispatch();


  const getBorderStyles = (column) => {
    let classes = '';
    if (column === 0) {
      classes = 'col-start-1 col-span-3 flex flex-row justify-centeritems-center w-full border-b-2 border-b-black border-l-2 border-l-black border-r-2 border-r-black';
    }
    if (column === 1) {
      classes = 'col-start-4 col-span-1 flex flex-row justify-center items-center w-full border-b-2 border-b-black border-r-2 border-r-black';
    }
    if (column === 2) {
      classes = 'col-start-5 col-span-1 flex flex-row justify-center  items-center w-full border-b-2 border-b-black border-r-2 border-r-black';
    }
    if (column === 3) {
      classes = 'col-start-6 col-span-1 flex flex-row justify-center  items-center w-full border-b-2 border-b-black';
    }
    if (column === 4) {
      classes = 'col-start-7 col-span-1 flex flex-row justify-center items-center w-full border-b-2 border-b-black border-l-2 border-l-black';
    }
    if (column === 5) {
      classes = 'col-start-8 col-span-1 flex flex-row justify-center items-center w-full border-b-2 border-b-black border-l-2 border-l-black';
    }
    if (column === 6) {
      classes = 'col-start-9 col-span-1 flex flex-row justify-center items-center w-full border-b-2 border-b-black border-l-2 border-l-black';
    }
    if (column === 7) {
      classes = 'col-start-10 col-span-1 flex flex-row justify-center items-center w-full border-b-2 border-b-black border-l-2 border-l-black';
    }
    if (column === 8) {
      classes = 'col-start-11 col-span-2 flex flex-row justify-end items-center w-full border-l-2 border-l-black border-r-2 border-r-black border-b-2 border-b-black';
    }
    if (index === length - 1) {
      return classes + " border-b-2 border-b-black";
    } else {
      return classes.replace(" border-b-2 border-b-black", "")
    }
  }

  const deleteRow1 = (rowId) => {
    reduceRowInserted();
    dispatch(deleteRow(rowId));
  }

  return (
    <>
      <div className='flex flex-row mx-[20%] text-sm'>
        <div className='grid grid-cols-12 w-full'>
          <div
            className={getBorderStyles(0)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.description}
            </div>
          </div>
          <div
            className={getBorderStyles(1)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.width}
            </div>
          </div>
          <div
            className={getBorderStyles(2)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.length}
            </div>
          </div>
          <div
            className={getBorderStyles(3)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.unit}
            </div>
          </div>
          <div
            className={getBorderStyles(4)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.grossSize}
            </div>
          </div>
          <div
            className={getBorderStyles(5)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.quantity}
            </div>
          </div>
          <div
            className={getBorderStyles(6)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.netSize}
            </div>
          </div>
          <div
            className={getBorderStyles(7)}>
            <div className="w-full m-0 p-1 border-0 font-semibold text-center">
              {row.price}
            </div>
          </div>
          <div
            className={getBorderStyles(8)}>
            <div className='flex flex-row justify-between'>
              <div className="w-full m-0 p-1 border-0 font-semibold text-right">
                {row.totalPrice}
              </div>
              <div>
                <XCircleIcon
                  className='h-6 fill-red-500 border-0 text-white items-center'
                  onClick={() => deleteRow1(row.id)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceItems;