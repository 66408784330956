const Footer = () => {
  return (<>
    <div className='flex flex-row justify-center mt-3'>
      <div className='flex flex-col justify-center font-semibold text-2xl'>
        <div className="text-center">
          <span className="text-yellow-600">"</span> 1 Pc 80 GSM A4 Paper = 0.00017 Trees.</div>
        <div className="text-center">Save Paper. Save Trees. <span className="text-yellow-600">"</span> </div>
      </div>
    </div>
    <div className='flex flex-row justify-center mt-3 text-2xl font-semibold'>
      BRANDING
      <span className='text-yellow-400 mx-2'>||</span> PRINTING
      <span className='text-yellow-400 mx-2'>||</span> ADVERTISING
      <span className='text-yellow-400 mx-2'>||</span> ENGINEERING
    </div>
  </>);
}

export default Footer;