import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addRow } from "../../redux/actions/actions";

const RowInputModal = ({ submittedRow }) => {
  const [description, setDescription] = useState("");
  const [width, setWidth] = useState(0);
  const [length, setLength] = useState(0);
  const [unit, setUnit] = useState("");
  const [grossSize, setGrossSize] = useState(0);
  const [quantity, setQunatity] = useState(0);
  const [netSize, setNetSize] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const selector = useSelector((state) => state.invoice);
  const dispatch = useDispatch();

  const handleAddRow = () => {
    const length = selector.rows.length + 1;
    const row = {
      "id": length,
      "description": description,
      "width": width,
      "length": length,
      "unit": unit,
      "grossSize": grossSize,
      "quantity": quantity,
      "netSize": netSize,
      "price": price,
      "totalPrice": totalPrice
    }
    console.log(row);
    setDescription("");
    setWidth(0);
    setLength(0);
    setUnit("");
    setGrossSize(0);
    setQunatity(0);
    setNetSize(0);
    setPrice(0);
    setTotalPrice(0);
    dispatch(addRow(row));
    submittedRow();
  }

  useEffect(() => {
    if (width && length) {
      setGrossSize(width * length);
    }
  }, [width, length])

  useEffect(() => {
    if (quantity && grossSize) {
      setNetSize(grossSize * quantity);
    }
  }, [quantity, grossSize])

  useEffect(() => {
    if (netSize && price) {
      setTotalPrice(netSize * price);
    }
  }, [netSize, price])

  const handleWidth = (e) => {
    const val = parseFloat(e.target.value);
    if (val) {
      setWidth(val);
    } else {
      setWidth(0);
    }
  }

  const handleLength = (e) => {
    const val = parseFloat(e.target.value);
    if (val) {
      setLength(val);
    } else {
      setLength(0);
    }
  }

  const handleQuantity = (e) => {
    const val = parseFloat(e.target.value);
    if (val) {
      setQunatity(val);
    } else {
      setQunatity(0);
    }
  }

  const handlePrice = (e) => {
    const val = parseFloat(e.target.value);
    if (val) {
      setPrice(val);
    } else {
      setPrice(0);
    }
  }

  return (<>
    <div className='flex flex-row mr-2 items-center'>
      <div className='font-semibold mt-1 mr-2'>Description:</div>
      <input
        type='text'
        placeholder='Enter description'
        className='font-semibold p-0 border-0'
        value={description}
        onChange={(e) => setDescription(e.target.value)} />
    </div>
    <div className='flex flex-row mr-2 items-center'>
      <div className='font-semibold mt-1 mr-2'>Width:</div>
      <input
        type='text'
        placeholder='Enter description'
        className='font-semibold p-0 border-0'
        value={width}
        onChange={handleWidth} />
    </div>
    <div className='flex flex-row mr-2 items-center'>
      <div className='font-semibold mt-1 mr-2'>Length:</div>
      <input
        type='text'
        placeholder='Enter description'
        className='font-semibold p-0 border-0'
        value={length}
        onChange={handleLength} />
    </div>
    <div className='flex flex-row mr-2 items-center'>
      <div className='font-semibold mt-1 mr-2'>Unit:</div>
      <input
        type='text'
        placeholder='Enter Unit'
        className='font-semibold p-0 border-0'
        value={unit}
        onChange={(e) => setUnit(e.target.value)} />
    </div>
    <div className='flex flex-row mr-2 items-center'>
      <div className='font-semibold mt-1 mr-2'>Quantity:</div>
      <input
        type='text'
        placeholder='Enter description'
        className='font-semibold p-0 border-0'
        value={quantity}
        onChange={handleQuantity} />
    </div>
    <div className='flex flex-row mr-2 items-center'>
      <div className='font-semibold mt-1 mr-2'>Price:</div>
      <input
        type='text'
        placeholder='Enter description'
        className='font-semibold p-0 border-0'
        value={price}
        onChange={handlePrice} />
    </div>
    <div className='mt-2 flex'>
      <div
        className='bg-blue-500 
            text-white font-semibold 
            rounded-lg px-2 py-2 w-fit
            cursor-pointer text-sm'
        onClick={handleAddRow}>
        Submit
      </div>
    </div>
  </>)
}

export default RowInputModal;