import StaticHeaders from './InvoicePreview/StaticHeaders';
import InvoiceDateName from './InvoicePreview/InvoiceDateName';
import InvoiceDetails from './InvoicePreview/InvoiceDetails';
import StaticACDetails from './InvoicePreview/StaticACDetails';
import Footer from './InvoicePreview/Footer';


const InvoicePreview = () => {
  return (
    <div className="container mx-auto -mt-1 flex flex-col">
      <StaticHeaders />
      <InvoiceDateName />
      <InvoiceDetails />
      <StaticACDetails />
      <Footer />
    </div>
  )
}

export default InvoicePreview;