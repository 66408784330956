import InvoiceItems from "./InvoiceItems";
import { PlusCircleIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setInvoiceNumber, addArtworkCharge, addFreightCharge } from "../../redux/actions/actions";
import { toWord } from "../../utils/number";

const InvoiceDetails = () => {
  const selector = useSelector((state) => state.invoice);
  const dispatch = useDispatch();

  const [numberOfItemsToInsert, setNumberOfItemsToInsert] = useState(0);
  const [numberOfItemsInserted, setNumberOfItemsInserted] = useState(0);

  const handleInvoiceNumber = (e) => {
    dispatch(setInvoiceNumber(e.target.value));
  }

  const handleAddArtworkCharge = (e) => {
    dispatch(addArtworkCharge(e.target.value));
  }

  const addItem = () => {
    setNumberOfItemsToInsert(numberOfItemsToInsert + 1);
  }

  const reduceRowInserted = () => {
    setNumberOfItemsInserted(numberOfItemsInserted - 1);
  }

  useEffect(() => {
    setNumberOfItemsInserted(selector.rows.length);
  }, [selector.rows.length]);

  useEffect(() => {
    console.log(selector)
  }, [selector]);

  return (
    <>
      <div className='flex flex-row w-full mx-[20%] mt-3'>
        <div className='flex flex-row justify-center w-2/3 font-bold'>
          <span className='mr-1'>INVOICE: </span>
          <span className='font-bold w-1/12  p-0 border-0'>{selector.invoiceNumber}</span>
        </div>
      </div>
      <div className='flex flex-row mx-[20%] mt-3 text-sm'>
        <div className='grid grid-cols-12 w-full bg-green-300'>
          <div
            className='
                col-start-1 
                col-span-3 
                flex flex-row 
                justify-center items-center
                border-2 border-black'>
            <div className='font-bold text-black'>Description</div>
          </div>
          <div
            className='
                col-start-4 
                col-span-3 
                flex flex-row
                border-y-2 border-b-black border-t-black'>
            <div className='flex flex-col w-full'>
              <div className='flex flex-row border-b-2 border-b-black justify-center'>
                <div className="flex flex-row">
                  <div className='font-bold text-black'>Details</div>
                </div>
              </div>
              <div className='grid grid-cols-3'>
                <div
                  className='
                      col-start-1 
                      col-span-1 
                      text-center 
                      font-bold 
                      text-black
                      border-r-2 border-r-black'>
                  Width
                </div>
                <div
                  className='
                      col-start-2 
                      col-span-1 
                      text-center 
                      font-bold 
                      text-black
                      border-r-2 border-r-black'>
                  Length
                </div>
                <div
                  className='
                      col-start-3 
                      col-span-1 
                      text-center 
                      font-bold 
                      text-black'>
                  Unit
                </div>
              </div>
            </div>
          </div>
          <div className='
                col-start-7 col-span-1 
                border-l-2 border-l-black 
                flex flex-row 
                justify-center items-center
                border-y-2 border-y-black'>
            <div className='font-bold text-black'>Gross Size</div>
          </div>
          <div className='
                col-start-8 col-span-1 
                border-l-2 border-l-black 
                flex flex-row 
                justify-center items-center
                border-y-2 border-y-black'>
            <div className='font-bold text-black'>
              <div>Quantity</div>
              <div>(Pc)</div>
            </div>
          </div>
          <div className='
                col-start-9 col-span-1 
                border-l-2 border-l-black 
                flex flex-row 
                justify-center items-center
                border-y-2 border-y-black'>
            <div className='font-bold text-black'>
              <div>Net Size</div>
            </div>
          </div>
          <div className='
                col-start-10 col-span-1 
                border-l-2 border-l-black 
                flex flex-row 
                justify-center items-center
                border-y-2 border-y-black'>
            <div className='font-bold text-black'>
              <div>Price/Unit</div>
            </div>
          </div>
          <div className='
                col-start-11 col-span-2 
                border-l-2 border-l-black 
                flex flex-row 
                justify-center items-center
                border-y-2 border-y-black
                border-r-2 border-r-black'>
            <div className='font-bold text-black'>
              <div>Total Price</div>
            </div>
          </div>
        </div>
      </div>
      {
        numberOfItemsInserted > 0 && [...Array(numberOfItemsInserted)].map((item, index) =>
          <div>
            <InvoiceItems
              key={index}
              index={index}
              length={numberOfItemsInserted}
              row={selector.rows[index]}
              reduceRowInserted={reduceRowInserted} />
          </div>
        )
      }
      <div className='flex flex-row mx-[20%] text-sm'>
        <div className='grid grid-cols-12 w-full'>
          <div
            className='col-start-1 col-span-10 
              flex flex-row p-1
              justify-end items-center
              border-l-2 border-l-black
              border-b-2 border-b-black'>
            <div className="font-bold">Artwork Charges</div>
          </div>
          <div className='
            col-start-11 col-span-2 
            text-right font-semibold p-1
            border-r-2 border-r-black
            border-b-2 border-b-black
            border-l-2 border-l-black'>
            <div className='flex flex-row justify-between'>
              <span className=''>
                {selector.artworkCharge}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row mx-[20%] text-sm'>
        <div className='grid grid-cols-12 w-full'>
          <div
            className='col-start-1 col-span-10 
              flex flex-row p-1
              justify-end items-center
              border-l-2 border-l-black
              border-b-2 border-b-black'>
            <div className="font-bold">Freight</div>
          </div>
          <div className='
            col-start-11 col-span-2 
            text-right font-semibold p-1
            border-r-2 border-r-black
            border-b-2 border-b-black
            border-l-2 border-l-black'>
            <div className='flex flex-row justify-between'>
              <span className=''>
                {selector.freightCharge}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row mx-[20%] text-sm'>
        <div className='grid grid-cols-12 w-full h-28'>
          <div className='col-start-1 col-span-10 border-l-2 border-black border-b-2'></div>
          <div className='col-start-11 col-span-2 border-l-2 border-black border-b-2 border-r-2'></div>
        </div>
      </div>
      <div className='flex flex-row mx-[20%] text-sm bg-yellow-300'>
        <div className='grid grid-cols-12 w-full'>
          <div className='col-start-1 col-span-10 border-l-2 border-b-2 border-black'>
            <div className='font-bold text-semibold text-right p-1'>
              Subtotal
            </div>
          </div>
          <div className='col-start-11 col-span-2 border-r-2 border-b-2 border-l-2 border-black text-right'>
            <div className='font-bold text-semibold text-right p-1'>
              {selector.subtotal}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row mx-[20%] text-sm'>
        <div className="flex flex-row w-full justify-start">
          <div className='font-semibold text-black border-black border-b-2 border-l-2 border-r-2 w-full p-1'>
            {'RUPEE(S) ' + selector.subtotalText}
          </div>
        </div>
      </div>
    </>
  )
}

export default InvoiceDetails;