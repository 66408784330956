import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setInvoiceNumber,
  addArtworkCharge,
  addFreightCharge,
  setName,
  setDate,
  calculateSubtotal
} from "../redux/actions/actions";
import RowInputModal from "./InvoiceDetailInput/RowInputModal";

const InsertDetailInput = () => {
  const selector = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  const [showAddRow, setShowAddRow] = useState(false);

  const updateInvoiceNumber = (e) => {
    dispatch(setInvoiceNumber(e.target.value));
  }

  const handleNameChange = (e) => {
    dispatch(setName(e.target.value));
  }

  const handleDateChange = (e) => {
    dispatch(setDate(e.target.value));
  }

  const handleAddArtworkCharge = (e) => {
    const val = parseInt(e.target.value);
    console.log("artwork", val);
    if (val) {
      dispatch(addArtworkCharge(val));
    } else {
      dispatch(addArtworkCharge(0));
    }
  }

  const handleFreightCharge = (e) => {
    const val = parseInt(e.target.value);
    console.log("freight", val);
    if (val) {
      dispatch(addFreightCharge(val));
    } else {
      dispatch(addFreightCharge(0));
    }

  }

  const addRow = () => {
    setShowAddRow(true);
  }

  const submittedRow = () => {
    setShowAddRow(false);
  }

  const calculateSubtotalLocal = () => {
    dispatch(calculateSubtotal());
  }

  return (
    <>
      <div className='font-semibold text-lg'>
        Enter details for invoice:
      </div>
      <div className='mt-3'>
        <span className='font-semibold mr-1'>Invoice Number: </span>
        <input
          placeholder='Enter Invoice Number'
          value={selector.invoiceNumber}
          onChange={updateInvoiceNumber} />
      </div>
      <div className='mt-2'>
        <span
          className='mr-2 
                      font-semibold
                      text-black'
        >Date: </span>
        <input type="date" placeholder='Enter date'
          className='font-semibold p-0 border-0'
          onChange={handleDateChange}
          value={selector.date} />
      </div>
      <div className='mt-2'>
        <span
          className='mr-2 
                  font-semibold 
                  text-black 
                  outline-none 
                  active:outline-none 
                  hove:outline-none'
        >Invoice for </span>
        <input type="text" placeholder='Enter name'
          className='font-semibold p-0 border-0'
          onChange={handleNameChange}
          value={selector.name} />
      </div>
      <div className='mt-2'>
        <span
          className='mr-2 
                  font-semibold 
                  text-black 
                  outline-none 
                  active:outline-none 
                  hove:outline-none'
        >Artwork Charge </span>
        <input type="text" placeholder='Enter Amount'
          className='font-semibold p-0 border-0'
          onChange={handleAddArtworkCharge}
          value={selector.artworkCharge} />
      </div>
      <div className='mt-2'>
        <span
          className='mr-2 
                  font-semibold 
                  text-black 
                  outline-none 
                  active:outline-none 
                  hove:outline-none'
        >Freight Charge </span>
        <input type="text" placeholder='Enter Amount'
          className='font-semibold p-0 border-0'
          onChange={handleFreightCharge}
          value={selector.freightCharge} />
      </div>
      <div className='mt-2 flex flex-row justify-between'>
        <div
          className='bg-blue-500 
            text-white font-semibold 
            rounded-lg px-2 py-2 w-fit
            cursor-pointer text-sm'
          onClick={addRow}>
          Add Row
        </div>
        <div
          className='bg-blue-500 
            text-white font-semibold 
            rounded-lg px-2 py-2 w-fit
            cursor-pointer text-sm'
          onClick={calculateSubtotalLocal}>
          Calculate Subtotal
        </div>
      </div>
      {
        showAddRow &&
        <div>
          <RowInputModal submittedRow={submittedRow} />
        </div>
      }
    </>
  );
};

export default InsertDetailInput;