import './App.css';
import InsertDetailInput from './components/InsertDetailInput';
import InvoicePreview from './components/InvoicePreview';


function App() {


  return (
    <>
      <div className='w-full h-screen'>
        <div className='container px-6 mt-3 min-w-full h-full'>
          <div className='grid grid-cols-6 w-full'>
            <div className='col-start-1 col-span-2 mt-3'>
              <InsertDetailInput />
            </div>
            <div className='col-start-3 col-span-4'>
              <InvoicePreview />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
