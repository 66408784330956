import {
  ADD_ROW,
  CHANGE_DATE,
  CHANGE_NAME,
  CHANGE_INVOICE_NUMBER,
  DELETE_ROW,
  UPDATE_ROW,
  ADD_ARTWORK_CHARGE,
  ADD_FREIGHT_CHARGE,
  CALCULATE_SUBTOTAL
} from "../actions/types";
import { toWord } from "../../utils/number";

const initialState = {
  "date": "",
  "name": "",
  "invoiceNumber": "",
  "rows": [],
  "artworkCharge": 0,
  "freightCharge": 0,
  "subtotal": 0,
  "subtotalText": toWord(0).toUpperCase(),
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_DATE:
      return {
        ...state,
        date: action.payload
      }
    case CHANGE_NAME:
      return {
        ...state,
        name: action.payload
      }
    case CHANGE_INVOICE_NUMBER:
      return {
        ...state,
        invoiceNumber: action.payload
      }
    case ADD_ROW:
      let newRowsAdd = [...state.rows, action.payload];
      return {
        ...state,
        rows: newRowsAdd
      }
    case UPDATE_ROW:
      let rowId = action.payload.id;
      let row = action.payload;
      let length = state.rows.length;
      let index = -1;
      for (let i = 0; i < length; i++) {
        if (state.rows[i].id === rowId) {
          index = i;
        }
      }
      if (index !== -1) {
        let newRowsUpdate = [...state.rows];
        newRowsUpdate[index] = row;
        return {
          ...state,
          rows: newRowsUpdate
        }
      }
      return state;
    case DELETE_ROW:
      let rowIdToDelete = action.payload;
      let newRowsDelete = state.rows.filter(row => row.id !== rowIdToDelete);
      return {
        ...state,
        rows: newRowsDelete
      }
    case ADD_ARTWORK_CHARGE:
      return {
        ...state,
        artworkCharge: action.payload,
      }
    case ADD_FREIGHT_CHARGE:
      return {
        ...state,
        freightCharge: action.payload,
      }
    case CALCULATE_SUBTOTAL:
      let subtotal = 0;
      state.rows.forEach(row => {
        subtotal += row.totalPrice;
      });
      subtotal += state.artworkCharge;
      subtotal += state.freightCharge;
      return {
        ...state,
        subtotal: subtotal,
        subtotalText: toWord(subtotal).toUpperCase(),
      }
    default:
      return state
  }
}