import Sign from '../../assets/sign.png';

const StaticACDetails = () => {
  return (<>
    <div className='flex justify-between mx-[20%] items-center mt-1'>
      <div className='w-full flex flex-row justify-start items-center'>
        <div className='flex-1'>
          <div className='flex flex-col w-1/2'>
            <div className='bg-yellow-300 border-black border-2 text-center'>
              Payment Gateway
            </div>
            <div className='grid grid-cols-4'>
              <div className='col-start-1 col-span-1 
                    bg-red-600 
                    flex flex-col
                    justify-center items-center 
                    text-white font-semibold
                    border-black border-l-2 border-b-2'>
                <div className='text-[0.5rem]'>Corporate</div>
                <div>A/C</div>
              </div>
              <div className='col-start-2 col-span-3 flex flex-col w-full border-black border-x-2 border-b-2'>
                <div className='bg-red-600 font-semibold text-center text-white'>
                  Punjab National Bank
                </div>
                <div className='text-center font-semibold border-black border-y-2'>
                  No. 912050017851
                </div>
                <div className='text-center font-semibold'>
                  IFSC. PUNB0091220
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full flex-1 flex flex-row justify-center'>
          <img src={Sign} alt="sign" className='h-24' />
        </div>
      </div>
    </div>
  </>);
}

export default StaticACDetails;