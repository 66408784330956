import logo from '../../assets/logo.jpg';

const StaticHeaders = () => {
  return (
    <>
      <div className='flex justify-center'>
        <hr className='bg-black w-8/12 h-3' />
      </div>
      <div className='flex justify-between mx-[20%] items-center mt-3'>
        <div>
          <span className='uppercase text-yellow-500 font-bold text-5xl'>DEED</span>
          <span className='uppercase text-black font-bold text-5xl ml-2'>TRIDENT</span>
        </div>
        <div className='flex flex-row items-center'>
          <img
            src={logo}
            alt="DeedTrident Logo"
            className='w-24' />
          <div className='ml-2'>
            <span className='text-yellow-500 font-bold '>Smart Moves. </span>
            <span className='text-black font-bold '>Creative Thoughts.</span>
          </div>
        </div>
      </div>
      <div className='flex flex-row w-full justify-start mx-[20%] mt-3'>
        <div className='flex flex-col font-bold text-black'>
          <div>Bhutkihut, Siliguri, Jalpaiguri, 735135</div>
          <div>7908161223, 8250032404</div>
        </div>
      </div>
    </>
  );
}

export default StaticHeaders;